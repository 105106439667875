import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const PAGE_QUERY_FOOTER = graphql`
      query PageQueryFooter {
        allMarkdownRemark(
          filter: {
            frontmatter: {
            	category: {
                eq: "What We Do"
              }  
            }
          }
          sort: {
            order: ASC
            fields: [frontmatter___priority]
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                slug
                priority
              }
            }
          }
        }
      }
      `

const FooterServices = () => (
  <StaticQuery
    query={PAGE_QUERY_FOOTER}
    render={({allMarkdownRemark}) => (
      <>
        <h5 className="text-uppercase">What We Do</h5>
        <ul className="list-unstyled">
          {allMarkdownRemark.edges.map(edge => (
            <li key={edge.node.frontmatter.slug}>
              <Link to={edge.node.frontmatter.slug}>
                {edge.node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </>
    )}
  />
)

export default FooterServices
