import React from 'react'
import './footer.scss'

import FooterAbout from './footer-about'
import FooterServices from './footer-services'

const Footer = () => (
  <footer className="page-footer font-small blue pt-4">
    <div className="container text-center text-md-left">
      <div className="row">
        <div className="col-md-3 mb-md-0 mb-3">
          <h5>Wexler Law Group PLLC</h5>
          <p>
            Family, Real Estate, Traffic & Construction Attorneys. Representing
            clients throughout Westchester County & NYC.
          </p>
        </div>

        <hr className="clearfix w-100 d-md-none pb-3" />

        <div className="col-md-3 mb-md-0 mb-3">
          <FooterAbout />
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <FooterServices />
        </div>

        <div className="col-md-3 mb-md-0 mb-3 getInTouch">
          <h5 className="text-uppercase">Get In Touch</h5>
          <p>
            <i className="fas fa-home mr-3" />
            933 Mamaroneck Ave #204{'\n'}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Mamaroneck, NY 10543
          </p>
          <p>
            <i className="fas fa-envelope mr-3" />
            ltwexleresq@gmail.com
          </p>
          <p>
            <i className="fas fa-phone mr-3" />
            (914) 835-1600
          </p>
          <p>
            <i className="fas fa-print mr-3" />
            (914) 835-1636
          </p>
        </div>
      </div>
    </div>

    <div className="footer-copyright text-center py-3">
      © {new Date().getFullYear()} Copyright: Wexler Law Group PLLC All Rights
      Reserved. | (914) 835-1600
    </div>
  </footer>
)

export default Footer
