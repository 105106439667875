import { Link } from 'gatsby'
import React from 'react'

// Components
import HeaderAbout from "./header-about"
import HeaderServices from "./header-services"

// CSS / Assets
import './header.scss'
import logo from '../../images/wlg_logo.png'

const Header = () => (
  <div className="Header">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <img
            className="navbar-brand-logo"
            src={logo}
            alt="Wexler Law Group Logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>

            <HeaderAbout/>

            <HeaderServices/>

          </ul>

          <span className="navbar-text">
            (914) 835-1600
          </span>
        </div>
      </nav>
    </div>
  </div>
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }
//
// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
