import React from 'react'

import './sidebar-form.scss'

const SidebarForm = () => (
  <div className="SidebarForm">
    <h2>Contact Us</h2>
    <form
      name="Sidebar-Form"
      method="POST"
      action="/success/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/*JSX-required input*/}
      <input type="hidden" name="form-name" value="Sidebar-Form" />
      {/*Honeypot field*/}
      <input type="hidden" name="bot-field" />
      {/*Real inputs*/}
      <div className="form-group">
        <label htmlFor="FormControlInput1">Name</label>
        <input
          type="text"
          className="form-control"
          name="Name"
          id="FormControlInput1"
        />
      </div>
      <div className="form-group">
        <label htmlFor="FormControlInput2">Email</label>
        <input
          type="email"
          className="form-control"
          name="Email"
          id="FormControlInput2"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="FormControlTextarea1">Message</label>
        <textarea
          className="form-control"
          name="Message"
          id="FormControlTextarea1"
          rows="3"
        />
      </div>
      <button type="submit" className="btn btn-primary btn-sidebar btn-sidebar">
        Submit
      </button>
    </form>
  </div>
)

export default SidebarForm
