import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const PAGE_QUERY_HEADER = graphql`
      query PageQueryHeader {
        allMarkdownRemark(
          filter: {
            frontmatter: {
            	category: {
                eq: "What We Do"
              }  
            }
          }
          sort: {
            order: ASC
            fields: [frontmatter___priority]
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                slug
                priority
              }
            }
          }
        }
      }
      `

const HeaderServices = () => (
  <StaticQuery
    query={PAGE_QUERY_HEADER}
    render={({allMarkdownRemark}) => (
      <>
        <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            What We Do
          </Link>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/what-we-do/">What We Do</Link>
            <div className="dropdown-divider"></div>

            {allMarkdownRemark.edges.map((edge) => (
              <Link
                key={edge.node.frontmatter.slug}
                className="dropdown-item"
                to={edge.node.frontmatter.slug}
              >
                {edge.node.frontmatter.title}
              </Link>
            ))}

          </div>
        </li>
      </>
    )}
  />
)

export default HeaderServices
