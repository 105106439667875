import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const ABOUT_QUERY_FOOTER = graphql`
      query AboutQueryFooter {
        allMarkdownRemark(
          filter: {
            frontmatter: {
            	category: {
                eq: "About Us"
              }  
            }
          }
          sort: {
            order: ASC
            fields: [frontmatter___priority]
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                slug
                priority
              }
            }
          }
        }
      }
      `

const FooterAbout = () => (
  <div className="FooterAbout">

    <StaticQuery
      query={ABOUT_QUERY_FOOTER}
      render={({allMarkdownRemark}) => (
        <>
          <h5 className="text-uppercase">About Us</h5>
          <ul className="list-unstyled">
            {allMarkdownRemark.edges.map((edge) => (
              <li
                key={edge.node.frontmatter.slug}
              >
                <Link to={edge.node.frontmatter.slug}>
                  {edge.node.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    />
  </div>
)



export default FooterAbout