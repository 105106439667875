import React from 'react'
import {Link} from 'gatsby'

import Layout from "../components/layout/layout";
import SEO from "../components/seo";

import FamilyLaw2 from "../images/family-law.jpg"
import RealEstateLaw from "../images/realestatelaw.jpeg"
import TrafficLaw from "../images/trafficlaw1.jpg"
import ConstructionLaw from "../images/constructionlaw.jpg"

import Sidebar from "../components/sidebar-form/sidebar-form"

import "./what-we-do.scss"

const WhatWeDo = () => (
  <Layout>
    <SEO
      title="Wexler Law Group PLLC"
      description="What We Do"
    />
    <div className="container padding-top-bottom">
      <div className="row">
        <div className="col-md-8">
          <h1 className="what-we-do_h1">Our Practice Areas</h1>
          <div className="row">
            <div className="col-xs-8 col-sm-6">
              <Link to="/what-we-do/family-matrimonial-law/">
                <img src={FamilyLaw2} alt="Family Law" className="img-thumbnail grayscale"/>
                <div className="centered">FAMILY &rarr;</div>
              </Link>
            </div>
            <div className="col-xs-4 col-sm-6">
              <Link to="/what-we-do/real-estate-transactions/">
                <img src={RealEstateLaw} alt="Real Estate Law" className="img-thumbnail grayscale"/>
                <div className="centered">REAL ESTATE &rarr;</div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-8 col-sm-6">
              <Link to="/what-we-do/traffic-law/">
                <img src={TrafficLaw} alt="Traffic Law" className="img-thumbnail grayscale"/>
                <div className="centered">TRAFFIC &rarr;</div>
              </Link>
            </div>
            <div className="col-xs-4 col-sm-6">
              <Link to="/what-we-do/construction-litigation/">
                <img src={ConstructionLaw} alt="Construction Law" className="img-thumbnail grayscale"/>
                <div className="centered">CONSTRUCTION &rarr;</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <Sidebar/>
        </div>
      </div>
    </div>
  </Layout>
)

export default WhatWeDo
